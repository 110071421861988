export const genderOptions = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
  { label: "Non-binary", value: "non_binary" },
];

export const optStatus = [
  { label: "Opt Out", value: "opt_out" },
  { label: "Cancelled", value: "cancelled" },
];

export const sourceStatus = [
  { label: "Referred", value: "referred" },
  { label: "Enrolled", value: "enrolled" },
  { label: "Cancelled", value: "cancelled" }
];

export const patientType = [
  { label: "IP", value: "ip" },
  { label: "OP", value: "op" },
];

export const serviceLine = [
  { label: "IP", value: "ip" },
  { label: "OP", value: "op" },
];
export const phoneOptions = [
  { label: "Mobile", value: "mobile" },
  { label: "Home", value: "home" },
  { label: "Work", value: "work" },
];

export const administrativeGenderOptions = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
  { label: "Non-binary", value: "not_applicable" },
];

export const relationshipToPatientOptions = [
  { label: "Cadaver Donor", value: "40" },
  { label: "Child", value: "19" },
  { label: "Dependent of minor dependent", value: "24" },
  { label: "Emancipated minor", value: "36" },
  { label: "Employee", value: "20" },
  { label: "Father", value: "33" },
  { label: "Foster child", value: "10" },
  { label: "Grandchild", value: "05" },
  { label: "Grandparent", value: "04" },
  { label: "Handicapped dependent", value: "22" },
  { label: "Injured plaintiff", value: "41" },
  { label: "Life Partner", value: "53" },
  { label: "Mother", value: "32" },
  { label: "Natural Child, insured does not have financial responsibility", value: "43" },
  { label: "Niece or nephew", value: "07" },
  { label: "Organ Donor", value: "39" },
  { label: "Self", value: "18" },
  { label: "Significant other", value: "29" },
  { label: "Sponsored dependent", value: "23" },
  { label: "Spouse", value: "01" },
  { label: "Stepchild", value: "17" },
  { label: "Unknown", value: "21" },
  { label: "Ward of the court", value: "15" },
  { label: "Other Relationship", value: "G8" },
];

export const desigationOptions = ["Primary", "Secondary"].map((value) => ({
  label: value,
  value: value.toLowerCase(),
}));

export const planTypeOptions = [
  "Self-pay",
  "Other Non-Federal Programs",
  "Preferred Provider Organization (PPO)",
  "Point of Service (POS)",
  "Exclusive Provider Organization (EPO)",
  "Indemnity Insurance",
  "Health Maintenance Organization (HMO) Medicare Risk",
  "Dental Maintenance Organization",
  "Automobile Medical",
  "Blue Cross/Blue Shield",
  "CHAMPUS",
  "Commercial Insurance Co.",
  "Disability",
  "Federal Employees Program",
  "Health Maintenance Organization (HMO)",
  "Liability Medical",
  "Medicare Part A",
  "Medicare Part B",
  "Medicaid",
  "Other Federal Program",
  "Title V",
  "Veterans Affairs Plan",
  "Workers Compensation Health Claim",
  "Mutually Defined",
].map((value) => ({
  label: value,
  value,
}));
